import React from 'react';
import Satellites from '../../Satellites/satellites';
import CGSTLArchive100cm from '../../Satellites/CGSTL/cgstl-archive-100cm';
import CGSTLArchive50cm from '../../Satellites/CGSTL/cgstl-archive-50cm';
import CGSTLArchive75cm from '../../Satellites/CGSTL/cgstl-archive-75cm';
import CGSTLDrawer from '../../Satellites/CGSTL/cgstl-drawer';
import LandsatDrawer from '../../Satellites/Landsat/landsat-drawer';
import SentinelDrawer from '../../Satellites/Sentinel/sentinel-drawer';
import Skymap50NewCollect from '../../Satellites/Skymap50/NewCollect/skymap50-new-collect';
import Skymap50Archive from '../../Satellites/Skymap50/skymap50-archive';
import Skymap50Drawer from '../../Satellites/Skymap50/skymap50-drawer';
import styled from 'styled-components';

enum SatellitePaths {
    DEFAULT = '/satellites',
    SKYMAP50 = '/satellites/skymap50',
    SKYMAP50_NEWCOLLECT = '/satellites/skymap50/new-collect',
    SKYMAP50_ARCHIVE = '/satellites/skymap50/archive',
    CGSTL = '/satellites/cgstl',
    CGSTL_ARCHIVE50 = '/satellites/cgstl/archive50',
    CGSTL_ARCHIVE75 = '/satellites/cgstl/archive75',
    CGSTL_ARCHIVE100 = '/satellites/cgstl/archive100',
    SENTINEL = '/satellites/sentinel',
    LANDSAT = '/satellites/landsat',
}

const SideDrawerSatellites = () => {
    const satellitePath = window.location.pathname;

    switch (satellitePath) {
        case SatellitePaths.DEFAULT:
            return (
                <React.Fragment>
                    <HeaderText>Satellite Search</HeaderText>
                    <SubHeadertext>Choose a Search</SubHeadertext>
                    <Satellites />
                </React.Fragment>
            );
        case SatellitePaths.SKYMAP50:
            return <Skymap50Drawer />;
        case SatellitePaths.SKYMAP50_NEWCOLLECT:
            return <Skymap50NewCollect />;
        case SatellitePaths.SKYMAP50_ARCHIVE:
            return <Skymap50Archive />;
        case SatellitePaths.CGSTL:
            return <CGSTLDrawer />;
        case SatellitePaths.CGSTL_ARCHIVE50:
            return <CGSTLArchive50cm />;
        case SatellitePaths.CGSTL_ARCHIVE75:
            return <CGSTLArchive75cm />;
        case SatellitePaths.CGSTL_ARCHIVE100:
            return <CGSTLArchive100cm />;
        case SatellitePaths.SENTINEL:
            return <SentinelDrawer />;
        case SatellitePaths.LANDSAT:
            return <LandsatDrawer />;
        default:
            return <React.Fragment />;
    }
};

export default SideDrawerSatellites;

const HeaderText = styled.h1`
    text-align: center;
    font-size: 22px;
    color: white;
    margin: 15px 0px 0px 0px;
`;

const SubHeadertext = styled.h2`
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    display: block;
    margin-top: 4px;
    margin-bottom: 12px;
    text-align: center;
    font-size: 1rem;
`;
