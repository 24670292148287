import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SoarModal from '../../../../Shared/soar-modal';
import { DrawPage } from '../draw-story';
import StoryBuilderPageItemMaps from './story-builder-page-item-maps';
import StoryBuilderPageItemMapsAddButton from './story-builder-page-item-maps-add-button';
import { Divider } from '../../ToolBar/toolbar-modal';
import { StyledButton } from '../../../../Shared/styled-button';
import SearchModal from '../../../../SearchModal/search-modal';
import { MarkdownTextArea } from '../../../../Shared/markdown-textarea';
import Analytics from '../../../../../lib/user-analytics';
import StoryBuilderBaseMapControl from './story-builder-basemap-control';

interface StoryBuilderPageDetailsProps {
    isOpen: boolean;
    page: DrawPage;
    onToggle: () => void;
    onUpdatePageTitle: (title: string) => void;
    onUpdatePageDescription: (description: string) => void;
    onUpdateMapFromPage: (page: DrawPage) => void;
    onDeleteMapFromPage: (page: DrawPage) => void;
    onSelectListing: (listingId: number) => void;
    onMovePageUp: () => void;
    onMovePageDown: () => void;
    onSetPageViewport: (locked: boolean) => void;
    onSetAllPagesViewport: (allLocked: boolean) => void;
}

const PageTitleMaxLength = 100;

const StoryBuilderPageDetailsModal = (props: StoryBuilderPageDetailsProps) => {
    const [showSearchModal, setShowSearchModal] = useState(false);
    const [showSetViewportNotification, setShowSetViewportNotification] = useState(false);
    const [showSetAllPagesViewportNotification, setShowSetAllPagesViewportNotification] = useState(false);
    const [pageTitle, setPageTitle] = useState<string | undefined>(props.page.pageTitle);

    useEffect(() => {
        if (props.page) {
            setShowSetViewportNotification(!props.page.allViewportsLocked && !!props.page.viewportLocked);
            setShowSetAllPagesViewportNotification(!!props.page.allViewportsLocked);
        }
    }, [props.page]);

    const handleClickSetViewport = () => {
        const newLockedState = !props.page.viewportLocked;
        setShowSetViewportNotification(newLockedState);
        setShowSetAllPagesViewportNotification(false);
        props.onSetPageViewport(newLockedState);
    };

    const handleClickSetAllPagesViewport = () => {
        const newAllLockedState = !props.page.allViewportsLocked;
        setShowSetAllPagesViewportNotification(newAllLockedState);
        setShowSetViewportNotification(false);
        props.onSetAllPagesViewport(newAllLockedState);
    };

    return (
        <SoarModal isOpen={props.isOpen} toggle={() => props.onToggle()} title={`Edit page details`}>
            <Container>
                <TextFieldContainer>
                    <Label>Page Title:</Label>
                    <TextField
                        value={pageTitle ?? ''}
                        onChange={(e) => {
                            setPageTitle(e.target.value);
                            props.onUpdatePageTitle(e.target.value);
                        }}
                        maxLength={PageTitleMaxLength}
                    />
                    <TextFieldNotice>
                        <TextFieldLimit>
                            Characters: {pageTitle?.length ?? 0}/{PageTitleMaxLength}
                        </TextFieldLimit>
                    </TextFieldNotice>
                </TextFieldContainer>
                <TextFieldContainer>
                    <Label>Page Description:</Label>
                    <TextArea
                        value={props.page.description}
                        setInputValue={props.onUpdatePageDescription}
                        initialEditorHeight={120}
                        placeholder="Add a description for this page..."
                    />
                </TextFieldContainer>

                <Label>Map View Settings:</Label>
                <Row>
                    <ControlButton className="set-view-button" onClick={handleClickSetViewport}>
                        <ControlButtonIcon className="fa fa-expand" />
                        Set this page to the current map view
                    </ControlButton>
                    {!showSetAllPagesViewportNotification && showSetViewportNotification ? (
                        <ViewportNotificationText>
                            <ViewportNotificationTextIcon src="/assets/annotations/closed-padlock.png" />
                            <span>Map view locked</span>
                        </ViewportNotificationText>
                    ) : (
                        <ViewportNotificationText>
                            <ViewportNotificationTextIcon src="/assets/annotations/open-padlock.png" />
                        </ViewportNotificationText>
                    )}
                </Row>
                <Row>
                    <ControlButton className="set-view-button" onClick={handleClickSetAllPagesViewport}>
                        <ControlButtonIcon className="fa fa-expand-arrows-alt" />
                        Set every page to the current map view
                    </ControlButton>
                    {showSetAllPagesViewportNotification ? (
                        <ViewportNotificationText>
                            <ViewportNotificationTextIcon src="/assets/annotations/closed-padlock.png" />
                            <span>Map views set</span>
                        </ViewportNotificationText>
                    ) : (
                        <ViewportNotificationText>
                            <ViewportNotificationTextIcon src="/assets/annotations/open-padlock.png" />
                        </ViewportNotificationText>
                    )}
                </Row>

                <Label>Map Settings:</Label>
                {props.page.map ? (
                    <StoryBuilderPageItemMaps
                        page={props.page}
                        setShowSearchModal={() => {
                            Analytics.Event('Draw Tools - Project', `Clicked to replace map on project page`);
                            setShowSearchModal(true);
                        }}
                        onUpdateOpacity={props.onUpdateMapFromPage}
                        onDeleteMapFromPage={props.onDeleteMapFromPage}
                    />
                ) : (
                    <StoryBuilderPageItemMapsAddButton
                        setShowSearchModal={() => {
                            Analytics.Event('Draw Tools - Project', `Clicked to add new map to project page`);
                            setShowSearchModal(true);
                        }}
                    />
                )}

                <SearchModal
                    isOpen={showSearchModal}
                    toggle={() => setShowSearchModal(!showSearchModal)}
                    onSelectListing={(listingId: number) => {
                        Analytics.Event('Draw Tools - Project', `Added map to project from search`, listingId);
                        setShowSearchModal(false);
                        props.onSelectListing(listingId);
                    }}
                />

                {props.page.activeBaseMap ? (
                    <React.Fragment>
                        <Label>Basemap Setting:</Label>
                        <Row>
                            <StoryBuilderBaseMapControl basemap={props.page.activeBaseMap} />
                        </Row>
                        <br />
                    </React.Fragment>
                ) : null}

                <Label>Page Ordering:</Label>
                <Row>
                    <ControlButton onClick={props.onMovePageUp}>
                        <ControlButtonIcon className="fa fa-chevron-up" />
                        Move Page Up
                    </ControlButton>

                    <ControlButton onClick={props.onMovePageDown}>
                        <ControlButtonIcon className="fa fa-chevron-down" />
                        Move Page Down
                    </ControlButton>
                </Row>

                <Divider />
                <SaveButton onClick={() => props.onToggle()}>Save and close</SaveButton>
            </Container>
        </SoarModal>
    );
};

export default StoryBuilderPageDetailsModal;

const Container = styled.div`
    margin: 16px;
`;

const TextFieldContainer = styled.div`
    margin-bottom: 8px;
`;

const Label = styled.label`
    color: ${(props) => props.theme.color.lightGray};
    font-size: 0.9rem;
    font-weight: bold;
    margin: 5px 3px 2px 0;
`;

const TextFieldNotice = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const TextFieldLimit = styled.span`
    font-size: 0.8rem;
    margin-left: auto;
`;

const TextField = styled.input`
    width: 100%;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 1.2rem;
    outline: none;
    color: white;

    :hover {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :-webkit-autofill {
        :hover {
            border: 1px solid ${(props) => props.theme.color.lightGray};
        }
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :focus {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }
`;

const TextArea = styled(MarkdownTextArea)`
    width: 100%;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background-color: transparent !important;
    font-size: 1.2rem;
    outline: none;
    color: white;

    :hover {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }

    :-webkit-autofill {
        :hover {
            border: 1px solid ${(props) => props.theme.color.lightGray};
        }
        border: 1px solid ${(props) => props.theme.color.gray};
    }

    :focus {
        border: 1px solid ${(props) => props.theme.color.lightGray};
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 4px;
`;

const ViewportNotificationText = styled.span`
    margin: 6px 0 0 6px;
    white-space: nowrap;
    display: flex;

    span {
        margin-left: 8px;
        color: ${(props) => props.theme.color.red} !important;
    }
`;

const ViewportNotificationTextIcon = styled.img`
    width: 22px;
    height: 22px;
`;

const ControlButton = styled.button`
    display: flex;
    flex-direction: row;
    padding: 6px !important;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: ${(props) => props.theme.borderRadius};
    margin-bottom: 12px;
    color: white;
    background: none !important;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.6;
        border: 1px solid ${(props) => props.theme.color.lightGray} !important;
    }

    &.set-view-button {
        width: 300px;
    }
`;

const ControlButtonIcon = styled.i`
    margin: 0;
    padding: 0;
    margin-right: 12px;
    display: block;
    font-size: 22px;
    width: 22px;
    color: ${(props) => props.theme.color.yellow};
`;

const SaveButton = styled(StyledButton)`
    margin: 0 auto;
    display: block;
`;
