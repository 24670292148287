import styled, { keyframes } from 'styled-components';

interface MapSearchDrawlistingCountProps {
    listingLocationCount?: number;
}

const MapSearchDrawlistingCount = ({ listingLocationCount }: MapSearchDrawlistingCountProps) => {
    if (listingLocationCount === undefined) {
        return <SkeletonLoading />;
    }

    return (
        <MapSearchDrawlistingCountContainer easeIn={true}>
            {`${
                listingLocationCount
                    ? `${listingLocationCount.toLocaleString()} maps in this area`
                    : 'No maps in this area'
            }`}
        </MapSearchDrawlistingCountContainer>
    );
};

export default MapSearchDrawlistingCount;

const MapSearchDrawlistingCountContainer = styled.div<{ easeIn: boolean }>`
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    opacity: ${(easeIn) => (easeIn ? 1 : 0)};
    transition: opacity 0.3s ease, transform 0.3s ease;
    font-size: 16px;
    color: white;
    padding: 5px;
    margin-right: 10px;
    text-align: center;
`;

const shimmer = keyframes`
  0% {
    background-position: -400px 0;
  }
  100% {
    background-position: 400px 0;
  }
`;

const SkeletonLoading = styled.div`
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.05) 25%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0.05) 75%
    );
    background-size: 800px 100%;
    animation: ${shimmer} 1.5s infinite linear;
    border-radius: 6px;
    padding: 5px;
    margin-right: 10px;
    width: auto;
    height: 34px;
`;
