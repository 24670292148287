import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import { SelectableListing } from '../../../Search/search-results-masonary';
import TemporalCategoryPill from './temporal-category-pill';
import React from 'react';

export interface TemporalCategory {
    value: string;
    label: string;
    color: string;
    title?: string;
    fontColor?: string;
}

export const temporalCategoryOptions: TemporalCategory[] = [
    { value: '', label: 'Not Applicable', color: 'transparent' },
    {
        value: 'near-real-time',
        label: 'Near Real Time',
        color: '#F7D96A',
        title: 'Maps that are updated less than 24 hours apart. Usually less than 6-12 hours.',
    },
    { value: 'daily', label: 'Daily', color: '#8CE4A3', title: 'Maps that are updated once every 24 hours.' },
    {
        value: 'weekly',
        label: 'Weekly',
        color: '#8FAFFF',
        title: 'Maps that are updated once every few days or once a week.',
    },
    {
        value: 'within-monthly',
        label: 'Within A Month',
        color: '#D1A5E6',
        title: 'Maps that are updated once every few weeks but less than a month.',
    },
    { value: 'monthly', label: 'Monthly', color: '#FF9F9F', title: 'Maps that are updated once every month.' },
    {
        value: 'seasonal',
        label: 'Seasonal',
        color: '#FFAD8F',
        title: 'Maps that are updated seasonally. Usually between 3 to 6 months.',
    },
    { value: 'yearly', label: 'Yearly', color: '#FFFFFF', title: 'Maps that are updated once a year.' },
];

interface TemporalCategoryControlProps {
    listing: ListingDTO | SelectableListing;
    inverted?: boolean;
    margin?: string;
    fontSize?: string;
    borderBottom?: string;
    showTemporalCategories?: boolean;
    showDefaultCategories?: boolean;
}

const TemporalCategoryControl = ({
    listing,
    inverted,
    margin,
    fontSize,
    borderBottom,
    showTemporalCategories,
    showDefaultCategories,
}: TemporalCategoryControlProps) => {
    if (!listing.categories) return null;

    const getCategories = () => {
        const selectedTemporalCategory = temporalCategoryOptions.find((temporalCategory) =>
            listing.categories?.includes(temporalCategory.value)
        );

        const nonTemporalCategories = listing?.categories
            .filter((category) => !temporalCategoryOptions.some((tempCat) => tempCat.value === category))
            .map((category) => (
                <TemporalCategoryPill
                    key={category}
                    category={{ value: category, label: category, color: 'rgb(238 217 38 / 87%)' }}
                    inverted={true}
                    fontSize={fontSize}
                />
            ));

        if (
            showTemporalCategories &&
            showDefaultCategories &&
            (selectedTemporalCategory || nonTemporalCategories?.length)
        ) {
            return (
                <CategoryContainer margin={margin}>
                    {selectedTemporalCategory && (
                        <TemporalCategoryPill
                            category={selectedTemporalCategory}
                            inverted={inverted}
                            fontSize={fontSize}
                        />
                    )}
                    {nonTemporalCategories?.length && nonTemporalCategories}
                </CategoryContainer>
            );
        }

        if (showDefaultCategories && !showTemporalCategories && nonTemporalCategories?.length) {
            return (
                <CategoryContainer margin={margin} borderBottom={borderBottom}>
                    {nonTemporalCategories}
                </CategoryContainer>
            );
        }

        if (showTemporalCategories && !showDefaultCategories && selectedTemporalCategory) {
            return (
                <CategoryContainer margin={margin}>
                    <TemporalCategoryPill category={selectedTemporalCategory} inverted={inverted} fontSize={fontSize} />
                </CategoryContainer>
            );
        }

        return <React.Fragment></React.Fragment>;
    };

    return getCategories();
};

export default TemporalCategoryControl;

const CategoryContainer = styled.div<{ margin?: string; borderBottom?: string }>`
    padding: 0px 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    border-bottom: ${(props) => (props.borderBottom ? props.borderBottom : 'none')};
`;
