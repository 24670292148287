import { LatLng, LatLngBounds } from 'leaflet';
import { useState, useEffect } from 'react';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../../../api/api-analytics';
import { AnalyticsAction, SatelliteProvider } from '../../../../api/model';
import { compareSentinelFeatures } from '../../../../lib/sentinel-service';
import Analytics from '../../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../../store/App/actions';
import {
    actionSentinelChangeOpacity,
    actionSentinelEndBoxSelect,
    actionSentinelOpenDownload,
    actionSentinelOpenShare,
    actionSentinelSelectFeature,
    actionSentinelSelectFeatureOpacity,
} from '../../../../store/Map/Sentinel/actions';
import { SentinelFeature } from '../../../../store/Map/Sentinel/model';
import {
    selectSentinelError,
    selectSentinelOpacity,
    selectSentinelSelectedFeatureOpacity,
} from '../../../../store/Map/Sentinel/selectors';

const moment = require('moment');

interface SentinelItemProps {
    feature: SentinelFeature;
    selectedFeature?: SentinelFeature;
}

interface ContainerProps {
    isSelected: boolean;
}

const Detail = (props: { name: string; value: string; isSelected: boolean }) => {
    return (
        <DetailRow>
            <DetailName isSelected={props.isSelected} isMobile={isMobileVersion}>
                {props.name}:
            </DetailName>
            <DetailValue>{props.value}</DetailValue>
        </DetailRow>
    );
};

const SentinelItem = (props: SentinelItemProps) => {
    const sentinelSelectedFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);
    const sentinelOpacity = useSelector(selectSentinelOpacity);
    const sentinelError = useSelector(selectSentinelError);

    const dispatch = useDispatch();
    const openDownload = () => dispatch(actionSentinelOpenDownload());
    const changeOpacity = (opacity: number) => {
        dispatch(actionSentinelChangeOpacity({ id: props.feature.id, opacity }));
        dispatch(actionSentinelSelectFeatureOpacity(opacity));
    };
    const flyTo = (position: LatLng | LatLngBounds) => dispatch(actionFlyToOnMap(position));
    const openShare = () => dispatch(actionSentinelOpenShare());

    const [previewImageFailed, setPreviewImageFailed] = useState<boolean>(false);
    const opacity = sentinelOpacity[props.feature.id] || 1.0;

    const layer = () => {
        switch (props.feature.layer) {
            case 'TRUE_COLOR_PREVIEW':
            case 'TRUE_COLOR':
                return 'True Color';
            case 'FALSE_COLOR':
                return 'False Color';
            case 'GEOLOGY':
                return 'Geology';
            case 'NDVI':
                return 'NDVI';
            case 'SENTINEL_1_RADAR':
            case 'SENTINEL_1_GRD':
                return 'Radar';
            case 'LANDSAT_8':
                return 'Landsat 8';
            default:
                return '';
        }
    };

    const isSelected =
        (props.selectedFeature && compareSentinelFeatures(props.feature, props.selectedFeature)) ||
        // On mobile we only display one result and not all results but need the isSelected to
        // trigger as true to display the sharelink and transparency slider for the item
        (isMobileVersion && props.selectedFeature)
            ? true
            : false;

    // this should set the selected satellite's opacity when exiting from draw tools
    useEffect(() => {
        if (isSelected && sentinelSelectedFeatureOpacity && !sentinelOpacity[props.feature.id]) {
            dispatch(actionSentinelChangeOpacity({ id: props.feature.id, opacity: sentinelSelectedFeatureOpacity }));
        }
    }, [dispatch, isSelected, sentinelSelectedFeatureOpacity, props.feature.id, sentinelOpacity]);

    return (
        <SatelliteCardItem
            data-testid="landsat-feature"
            isSelected={isSelected}
            onClick={() => {
                // Fixes #4876 - Forces the sentinel feature to toggle to undefined before setting to allow updates of deeply nested state re-render
                dispatch(actionSentinelSelectFeature(undefined));
                requestAnimationFrame(() => {
                    dispatch(actionSentinelSelectFeature(props.feature));
                    dispatch(actionSentinelSelectFeatureOpacity(sentinelOpacity[props.feature.id]));
                });
                dispatch(actionSentinelEndBoxSelect());
                flyTo(props.feature.bbox);
                Analytics.Event('Satellite - Sentinel', 'Selected sentinel feature', props.feature.bbox.toBBoxString());
                ApiAnalytics.postAnalyticsSatellite(
                    props.feature.previewUrl,
                    SatelliteProvider.SINERGISE,
                    props.feature.satelliteAnalytics,
                    AnalyticsAction.VIEW
                );
            }}
        >
            <PreviewImage
                isSelected={isSelected}
                src={
                    previewImageFailed ? '/assets/image-unavailable/preview_unavailable.png' : props.feature.previewUrl
                }
                onError={() => setPreviewImageFailed(true)}
            />
            <DetailsContainer>
                <Detail isSelected={isSelected} name="Date" value={moment(props.feature.date).format('MMM Do YYYY')} />
                <Detail isSelected={isSelected} name="Layer" value={layer()} />
                <Detail
                    isSelected={isSelected}
                    name={isMobileVersion ? 'Resolution' : 'Pixel Resolution'}
                    value={props.feature.resolution}
                />
                <SliderDetailRow isSelected={isSelected}>
                    <DetailName isSelected={isSelected}>Transparency: </DetailName>
                    <DetailValue className="slider-detail-value">
                        <Slider
                            percentageFilled={opacity * 100}
                            type="range"
                            defaultValue={100}
                            value={opacity * 100}
                            step={0.01}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                                const opacity = parseInt(e.target.value) / 100;
                                changeOpacity(opacity || 0.0001);
                            }}
                        />
                    </DetailValue>
                </SliderDetailRow>
            </DetailsContainer>
            {isSelected ? (
                <ButtonContainer isSelected={isSelected} isDisabled={sentinelError ? true : false}>
                    <DownloadButton
                        title="Download"
                        onClick={() => {
                            openDownload();
                            Analytics.Event(
                                'Satellite - Sentinel',
                                'Clicked download',
                                props.feature.bbox.toBBoxString()
                            );
                        }}
                        disabled={sentinelError ? true : false}
                    >
                        <DownloadIcon src="/assets/floating-drawer-icons/download-icon.png" />
                    </DownloadButton>
                    <ShareButton
                        title="Share"
                        onClick={() => {
                            openShare();
                            Analytics.Event('Satellite - Sentinel', 'Clicked share', props.feature.bbox.toBBoxString());
                        }}
                        disabled={sentinelError ? true : false}
                    >
                        <ShareIcon src="/assets/floating-drawer-icons/share-icon.png" />
                    </ShareButton>
                </ButtonContainer>
            ) : null}
        </SatelliteCardItem>
    );
};

export default SentinelItem;

const SatelliteCardItem = styled.div<ContainerProps>`
    user-select: none;
    height: ${(props) => (props.isSelected ? '90px' : '70px')};
    background: ${(props) => (props.isSelected ? 'rgba(255,255,255,0.2)' : 'transparent')};
    cursor: ${(props) => (props.isSelected ? 'cursor' : 'pointer')};
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    justify-content: center;
    margin: 5px 4px 0px 5px;
    transition: height 0.25s ease-in-out;

    &:hover {
        background: ${(props) => (props.isSelected ? 'rgba(255,255,255,0.2)' : 'rgba(255,255,255,0.05)')};
    }

    @media (max-width: 600px) {
        background: transparent;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        &:hover {
            background: transparent;
        }
    }
`;

const PreviewImage = styled.img<ContainerProps>`
    height: ${(props) => (props.isSelected ? '88px' : '68px')};
    width: 68px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin: 0px;
    padding: 0px;
    object-fit: cover;
    transition: height 0.25s ease-in-out;
`;

const DetailsContainer = styled.div`
    user-select: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 9px;
`;

interface DetailRowProps {
    isSelected?: boolean;
}

const SliderDetailRow = styled.div<DetailRowProps>`
    user-select: none;
    display: flex;
    flex-direction: row;
    margin: 0 0 0 8px;
    padding: 0;
    opacity: ${(props) => (props.isSelected ? 1 : 0)};
    transition: opacity 0.4s ease-in;
`;

const DetailRow = styled.div`
    user-select: none;
    display: flex;
    flex-direction: row;
    margin: 0 0 0 8px;
    padding: 0;
`;

interface IsSelectedProps {
    isSelected: boolean;
    isMobile?: boolean;
}

const DetailName = styled.span<IsSelectedProps>`
    color: rgb(176, 176, 176);
    font-size: 0.85rem;
    width: ${(props) => (props.isMobile ? '80px' : '95px')};
    margin-top: -1px;
`;

const DetailValue = styled.span`
    color: white;
    font-size: 1rem;
    margin-top: -3px;

    &.slider-detail-value {
        margin-top: -5px;
    }
`;

interface SliderProps {
    percentageFilled: number;
}

const Slider = styled.input<SliderProps>`
    background-color: #eed926 !important;
    background: ${(props) =>
        `linear-gradient(to right, #eed926 0%, #eed926 ${props.percentageFilled}%, #999 ${props.percentageFilled}%, #999 100%);`};
    width: 95px;
    height: 2px;
    margin: -4px 0 0 0;
    box-shadow: none !important;
    border: none !important;
    -webkit-appearance: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;

    &::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;

        height: 12px;
        width: 12px;
        border-radius: 50%;
    }

    &:hover::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: #eed926;
        border-radius: 50%;
        cursor: pointer;
        -webkit-box-shadow: 0px 0px 10px 2px rgba(238, 217, 38, 0.69);
        -moz-box-shadow: 0px 0px 10px 2px rgba(238, 217, 38, 0.69);
        box-shadow: 0px 0px 10px 2px rgba(238, 217, 38, 0.69);
    }

    @media (max-width: 800px) {
        width: 85%;
    }
`;

interface ButtonProps {
    isDisabled?: boolean;
    isSelected?: boolean;
}

const ButtonContainer = styled.div<ButtonProps>`
    display: flex;
    flex-direction: column;
    opacity: ${(props) => (props.isSelected ? 1 : 0)};
    opacity: ${(props) => props.isDisabled && 0.6};
    transition: opacity 0.35s ease-in-out;
`;

const DownloadButton = styled.button`
    margin: 4px;
    margin-bottom: 0px;
    width: 30px;
    height: 30px;
    background-color: #eed926 !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    :focus {
        outline: none;
    }

    @media (max-width: 650px) {
        display: none;
    }
`;

const DownloadIcon = styled.img`
    height: 18px;
`;

const ShareButton = styled.button`
    margin: 4px;
    width: 30px;
    height: 30px;
    background-color: #eed926 !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;

    :focus {
        outline: none;
    }

    @media (max-width: 650px) {
        margin-top: 20px;
    }
`;

const ShareIcon = styled.img`
    margin: 3px -3px;
    height: 18px;
`;
