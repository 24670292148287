import React from 'react';
import { LatLng } from 'leaflet';
import { Polygon } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { selectHighlightedListingId } from '../../../../store/Map/MapSelection/selectors';
import { ListingDTO } from '../../../../api/model';
import UriHelper from '../../../../lib/uri-helper';
import { actionSetHighlightedListingId } from '../../../../store/Map/MapSelection/actions';
import { wktPolygonToLatLngArray } from '../../../../lib/geo-util';

interface ViewportGroupedPolygonProps {
    listings: ListingDTO[];
}

const MapSearchCluster = (props: ViewportGroupedPolygonProps) => {
    const highlightedListingId = useSelector(selectHighlightedListingId);
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            {props.listings?.map((listing: ListingDTO, index: number) => {
                if (!listing.bboxWKT) {
                    return;
                }

                return (
                    <Polygon
                        key={`${index}-${listing.id}-${highlightedListingId}`}
                        positions={wktPolygonToLatLngArray(listing.bboxWKT) as LatLng[]}
                        weight={2}
                        color={highlightedListingId === listing.id ? '#EED926' : '#3388ff'}
                        fillColor={highlightedListingId === listing.id ? 'rgba(238, 218, 38, 0.5)' : 'transparent'}
                        eventHandlers={{
                            click: () => UriHelper.navigateToMap(listing.id),
                            mouseover: () => dispatch(actionSetHighlightedListingId(listing.id)),
                        }}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default MapSearchCluster;
