import { useState, useEffect, useRef } from 'react';

const useWidthObserver = (threshold: number) => {
    const [isBelowThreshold, setIsBelowThreshold] = useState(false);
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            // eslint-disable-next-line prefer-const
            for (let entry of entries) {
                const width = entry.contentRect.width;
                if (width < threshold) {
                    setIsBelowThreshold(true);
                } else {
                    setIsBelowThreshold(false);
                }
            }
        });

        const element = elementRef.current;
        if (element) {
            observer.observe(element);
        }

        return () => {
            if (element) {
                observer.unobserve(element);
            }
        };
    }, [threshold]);

    return { elementRef, isBelowThreshold };
};

export default useWidthObserver;
