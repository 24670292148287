import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { CommentResultsDTO, ListingDTO } from '../../../../api/model';
import ActiveCommentActions from './comment-actions';
import CommentVote from './comment-vote';
import UriHelper from '../../../../lib/uri-helper';
import { useDispatch } from 'react-redux';
import { actionSetResetCommentSection, actionSetSelectedCommentId } from '../../../../store/SocialMapping/actions';
import Analytics from '../../../../lib/user-analytics';
import { deleteAllAnnotationsAction } from '../../../../store/Annotations/actions';
import { isMobileVersion } from '../../../../lib/soar-helper';
import { actionActiveMapCleared } from '../../../../store/Map/SuperMap/actions';

import ProfileName from '../../SideDrawer/ProfileDrawer/Profile/profile-name';
import StyledComment from '../../../Shared/styled-comment';
import UserAvatar from '../../../Shared/Avatar/user-avatar';

interface CommentDetailsProps {
    isReply?: boolean;
    comment: CommentResultsDTO;
    isSelected: boolean;
    handleOpenDeleteComment: () => void;
    handleEditComment: () => void;
    handleReportComment: () => void;
    handleModerateComment: () => void;
    listing: ListingDTO | number | string;
}

export const CommentDetails = (props: CommentDetailsProps) => {
    const dispatch = useDispatch();

    const handleSelectProfile = () => {
        dispatch(deleteAllAnnotationsAction());

        if (props.comment.userId) {
            const userId = props.comment.userId;
            if (userId) {
                UriHelper.navigateToPath(`/profile/${userId}`);
                Analytics.Event('Comments', 'Clicked comment profile', userId);
                dispatch(actionSetResetCommentSection(true));
                dispatch(actionSetSelectedCommentId(''));
            }
        }
        dispatch(actionActiveMapCleared());
    };

    return (
        <CommentDetailCard className="CommentDetailCard">
            <TopSection className="TopSection">
                <CommentVote comment={props.comment} />
                <TopSectionDetails className="TopSectionDetails" isSelected={props.isSelected}>
                    <UserAvatar user={props.comment} margin="0" diameter={40} onClick={handleSelectProfile} />
                    <UserDetails className="UserDetails">
                        <Username
                            isSelected={props.isSelected}
                            isReply={props.isReply}
                            userId={props.comment.userId}
                            isCommunityModerator={props.comment.communityModerator}
                            isReputable={props.comment.userReputable}
                            onClick={handleSelectProfile}
                        >
                            {props.comment.userName || 'Soar User'}
                        </Username>
                        <CommentDate>{moment(new Date(props.comment.createdAt * 1000)).fromNow()}</CommentDate>
                    </UserDetails>
                </TopSectionDetails>
                {props.isSelected && !isMobileVersion ? (
                    <ActiveCommentActions
                        handleCommentDelete={props.handleOpenDeleteComment}
                        handleCommentEdit={props.handleEditComment}
                        handleReportComment={props.handleReportComment}
                        handleModerateComment={props.handleModerateComment}
                        commentUserId={props.comment.userId}
                    />
                ) : null}
            </TopSection>
            <ContentSection className="ContentSection">
                <StyledComment text={props.comment.text || ''} isSelected={props.isSelected} />
            </ContentSection>
        </CommentDetailCard>
    );
};

interface CommentDetailProps {
    isSelected: boolean;
    isReply?: boolean;
}

const CommentDetailCard = styled.div`
    display: flex;
    flex-direction: column;
`;

const TopSection = styled.div`
    display: flex;
    flex-direction: row;
`;
const TopSectionDetails = styled.div<CommentDetailProps>`
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 5px 0 0 5px;
    position: relative;
    box-sizing: 'padding-box';

    width: calc(100% - 232px);
`;

const ContentSection = styled.div``;

const UserDetails = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 5px;
`;

export const Username = styled(ProfileName)<CommentDetailProps>`
    margin: 0 !important;
    font-size: 16px;
    display: flex;

    a {
        color: #eed926;
        max-width: ${(props) => {
            if (props.isSelected) {
                return props?.isReply ? '155px' : '165px';
            }
            return props?.isReply ? '175px' : '195px';
        }};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        font-weight: normal;
        display: inline-block;

        & ~ sup {
            font-size: 16px;
        }
    }
`;

const CommentDate = styled.span`
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    width: 120px;

    @media only screen and (max-width: 600px) {
        margin-top: 1px;
        font-size: 9px;
    }
`;
