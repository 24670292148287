import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ListingDTO, ListingType } from '../../../api/model';
import UriHelper from '../../../lib/uri-helper';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { actionSetResetCommentSection } from '../../../store/SocialMapping/actions';
import { CommentList } from '../../Drawer/Maps/Comments/comment-list';
import ShareComponent from '../../Drawer/Maps/ActiveMap/Sharing/map-options';
import { MarkdownViewer } from '../../Shared/markdown-viewer';
import ActiveMapOpacitySlider from './active-map-opacity-slider';
import { BottomSheetState } from './bottom-sheet';
import Analytics from '../../../lib/user-analytics';
import ActiveMapLegend from '../../Drawer/Maps/ActiveMap/active-map-legend';
import ProfileName from '../../Drawer/SideDrawer/ProfileDrawer/Profile/profile-name';
import { actionUpdateActiveSearchPosition } from '../../../store/Search/actions';
import MapViewHistoryHelper from '../../../lib/map-view-history-helper';
import { actionActiveMapCleared } from '../../../store/Map/SuperMap/actions';
import moment from 'moment';

interface ActiveMapBottomSheetProps {
    listing: ListingDTO;
    bottomSheetState: BottomSheetState;
    onCloseMap: () => void;
    isClosing: boolean;
}

const ActiveMapBottomSheet = (props: ActiveMapBottomSheetProps) => {
    const { isClosing, onCloseMap } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.listing) {
            MapViewHistoryHelper.addListingToViewedMapsHistory(props.listing);
        }
    }, [props.listing]);

    useEffect(() => {
        const closeActiveMap = () => {
            UriHelper.navigateToDrawer(SideDrawerMode.MAPS);
            dispatch(actionSetResetCommentSection(true));
            onCloseMap();
            Analytics.Event('Mobile - Active Map Bottom Sheet', 'Clicked Close Active Map Bottom Sheet');
        };

        if (isClosing) {
            closeActiveMap();
        }
    }, [dispatch, isClosing, onCloseMap]);

    // Clears the marker if the user selects a map or has added a marker then leaves the map
    // Note: This is an opinionated choice to have the marker removed when a map is selected
    // and could remain on the map until the search input has actually been cleared.
    useEffect(() => {
        const handleClearMarker = () => {
            dispatch(actionUpdateActiveSearchPosition(undefined));
        };

        handleClearMarker();
        return () => {
            handleClearMarker();
        };
    }, [dispatch]);

    const handleViewProfile = () => {
        dispatch(actionActiveMapCleared());
        UriHelper.navigateToPath(`/profile/${props.listing.userId}`);
    };

    return (
        <React.Fragment>
            <TopContainer bottomSheetClosed={props.bottomSheetState === BottomSheetState.close}>
                <MapDetails>
                    <Title singleLine={props.bottomSheetState === BottomSheetState.close}>{props.listing.title}</Title>
                    <Author>
                        by&nbsp;
                        <UserProfileLink
                            userId={props.listing.userId}
                            onClick={handleViewProfile}
                            isCommunityModerator={props.listing.communityModerator}
                            isReputable={props.listing.reputable}
                        >
                            {props.listing.userName}
                        </UserProfileLink>
                    </Author>
                    {props.listing.listingType !== ListingType.WMS && (
                        <DateUploaded>{moment(props.listing.dateUploaded).fromNow()}</DateUploaded>
                    )}
                </MapDetails>
            </TopContainer>
            <Divider />
            <ActiveMapOpacitySlider tileLayer={props.listing} />
            <Divider />
            <ShareComponent />
            <Divider />
            <DescriptionContainer>
                <MarkdownViewer text={props.listing.description} />
            </DescriptionContainer>
            {props.listing.tags?.length ? (
                <TagContainer>
                    {props.listing.tags.map((tag, index) => {
                        return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                    })}
                </TagContainer>
            ) : null}
            <ActiveMapLegend listing={props.listing} />
            <Divider />
            <CommentList // disabled for now incase it needs to be reactivated
                listingId={props.listing.id}
                commentUpdate={false}
                setCommentCount={() => ''}
                allowComment={false}
            />
        </React.Fragment>
    );
};

export default ActiveMapBottomSheet;

const TopContainer = styled.div<{ bottomSheetClosed: boolean }>`
    height: ${(props) => (props.bottomSheetClosed ? '100px' : 'auto')};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const MapDetails = styled.div`
    width: calc(100% - 60px);
`;

const Title = styled.h1<{ singleLine: boolean }>`
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    width: calc(100%); /* The trick is here! */
    color: white;
    text-align: left;
    font-size: 22px;
    line-height: 3rem;
    margin: 12px 0 0 12px;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: ${(props) => (props.singleLine ? 'nowrap' : 'unset')};
    overflow-wrap: ${(props) => (props.singleLine ? 'unset' : 'break-word')};
`;

const Author = styled.p`
    display: flex;
    text-overflow: ellipsis;
    width: calc(100%); /* The trick is here! */
    text-align: left;
    font-size: 16px;
    color: white;
    margin: 0 0 16px 12px;
    line-height: 21px;

    a {
        color: #eed926 !important;
        text-decoration: underline !important;
        pointer-events: all;
    }
`;

const UserProfileLink = styled(ProfileName)`
    display: flex;
    width: 100%;
    flex: 1;
    margin: 0 !important;
    font-size: inherit;

    a {
        display: block;
        min-width: 0;
        color: #eed926 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: normal;
    }
`;

const Divider = styled.div`
    height: 1px;
    width: 100vw;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

const DescriptionContainer = styled.div`
    margin: 12px 12px 0 12px;
`;

const TagContainer = styled.div`
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    margin: 0 12px 8px 12px;
    flex-wrap: wrap;
    max-height: auto;
    overflow: visible;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 1px 3px 3px 3px;
    line-height: 14px;
    opacity: 0.5;
    margin: 0 2px 2px 0;
    color: white;
    width: auto;
    word-break: break-all;
    font-size: 12px;
`;

const DateUploaded = styled.div`
    margin: -6px 0 6px 12px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
`;
