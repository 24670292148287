import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import ApiAutocomplete from '../../../../api/api-autocomplete';
import ApiListings from '../../../../api/api-listings';
import Analytics from '../../../../lib/user-analytics';
import { actionSetSelectedSearchTerm } from '../../../../store/Map/MapSelection/actions';
import { actionUpdateActiveSearchPosition } from '../../../../store/Search/actions';

const DEBOUNCE_TIME = 300; //ms

const FeaturedSearchInput = () => {
    const dispatch = useDispatch();
    const [searchInput, setSearchInput] = useState<string>('');

    const [debouncedSearchInput, setDebouncedSearchInput] = useState<string>('');
    const [searchSubject] = useState(() => new Subject<string>());
    const searchInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        Analytics.Event('Side Drawer', `Search Featured: ${debouncedSearchInput}`);
        dispatch(actionSetSelectedSearchTerm(debouncedSearchInput));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchInput]);

    useEffect(() => {
        searchSubject.next(searchInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    useEffect(() => {
        const subscription = searchSubject
            .pipe(
                tap(() => {
                    ApiAutocomplete.cancelAutoComplete('Cancel autocomplete');
                    ApiListings.cancelGetListings('Cancel get listings');
                    ApiListings.cancelAOIListing('Cancel AOI listings');
                }),
                debounceTime(DEBOUNCE_TIME)
            )
            .subscribe((next) => {
                setDebouncedSearchInput(next);
            });

        return () => {
            subscription.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <SearchInputContainer id="featured-search-input">
            <SearchInputItem>
                <Input
                    ref={searchInputRef}
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    type="text"
                    placeholder={"Search Soar's digital atlas..."}
                />

                {searchInput.length > 0 ? (
                    <CloseIconContainer>
                        <CloseIcon
                            onClick={() => {
                                setSearchInput('');
                                dispatch(actionUpdateActiveSearchPosition(undefined));
                                Analytics.Event('Search Bar', 'Search Cleared');
                            }}
                            src="/assets/close.png"
                        />
                    </CloseIconContainer>
                ) : null}
            </SearchInputItem>
        </SearchInputContainer>
    );
};

export default FeaturedSearchInput;

const SearchInputContainer = styled.div`
    width: 100%;
    height: 40px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    border: 1px solid rgb(81, 81, 81);
    margin: 10px 6px;
`;

const SearchInputItem = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
`;

const Input = styled.input`
    background: transparent;
    border: none;
    width: 100%;
    outline: none;
    color: white;
    padding: 0;
    margin: 0;
    font-size: 1rem;
    line-height: 40px;

    &::placeholder {
        color: ${(props) => props.theme.color.lightGray};
    }
`;

const CloseIconContainer = styled.div`
    margin-right: 7px;
    margin-top: -7px;
    height: 18px;
    width: 18px;
    cursor: pointer;
`;

const CloseIcon = styled.img`
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 21L20.9999 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M21 21L1.00006 0.999999' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
    cursor: pointer;
    vertical-align: bottom;
`;
