import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ListingHelper from '../../../../../lib/listing-helper';
import MapViewHistoryHelper from '../../../../../lib/map-view-history-helper';
import PreviewTile, { PreviewImageContainer, ContentContainer } from '../../../../Shared/preview-tile';
import { DrawPage, DrawPageMap } from '../draw-story';
import { actionSetTileLayerOpacity } from '../../../../../store/Map/TileLayer/actions';

interface StoryPageItemMapsProps {
    page: DrawPage;
    setShowSearchModal: () => void;
    onUpdateOpacity: (page: DrawPage) => void;
    onDeleteMapFromPage: (page: DrawPage) => void;
}

const StoryBuilderPageItemMaps = (props: StoryPageItemMapsProps) => {
    const dispatch = useDispatch();
    const setTileLayerOpacity = useCallback(
        (tileLayerId: number, opacity: number): void => {
            dispatch(actionSetTileLayerOpacity(tileLayerId, opacity));
        },
        [dispatch]
    );

    useEffect(() => {
        if (props.page.map) {
            MapViewHistoryHelper.setLastUsedDrawProjectMap(
                props.page.map.id,
                props.page.map.title,
                props.page.map.author
            );
        }
    }, [props.page]);

    const handleUpdateOpacity = (newOpacity: number) => {
        if (props.page.map) {
            setTileLayerOpacity(props.page.map.id, newOpacity);

            props.onUpdateOpacity({
                ...props.page,
                map: { ...props.page.map, opacity: newOpacity } as DrawPageMap,
            });
        }
    };

    if (!props.page.map) return <React.Fragment />;

    return (
        <Row>
            <PreviewContainer title="Swap this with another map">
                <PreviewTile
                    onClick={() => props.setShowSearchModal()}
                    previewUrl={ListingHelper.getPreviewUrlForListing(props.page.map.id, 'small')}
                    title={props.page.map.title}
                    subText={`by ${props.page.map.author || 'Soar User'}`}
                    opacity={props.page.map?.opacity ?? 1}
                    onUpdateOpacity={handleUpdateOpacity}
                    showPercentageText={true}
                />
            </PreviewContainer>
            <DeleteIcon
                src="/assets/annotations/icon-trash-red.svg"
                title="Remove map from this page"
                onClick={() => {
                    props.onDeleteMapFromPage({ ...props.page, map: undefined });
                }}
            />
        </Row>
    );
};

export default StoryBuilderPageItemMaps;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 68px;
    margin: 0 0 18px 0;
`;

const PreviewContainer = styled.div`
    display: block;
    height: 68px;
    width: calc(100% - 32px);
    border-radius: ${(props) => props.theme.borderRadius};
    overflow: hidden;
    position: relative;
    border: ${(props) => `1px solid ${props.theme.color.gray}`};

    ${PreviewImageContainer} {
        max-width: none !important;
    }

    ${ContentContainer} {
        margin: 2px 4px !important;
    }
`;

const DeleteIcon = styled.img`
    width: 24px;
    cursor: pointer;
`;
