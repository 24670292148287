import styled from 'styled-components';
import Analytics from '../../../../lib/user-analytics';

interface FeaturedSearchFilterProps {
    searchOrderBy: string; // TODO do we need hrmm mayeb use url params?
    setSearchOrderBy: (searchOrderBy: string) => void;
}

const FeaturedSearchFilter = ({ setSearchOrderBy }: FeaturedSearchFilterProps) => {
    return (
        <SearchFilterContainer>
            <Select
                defaultValue=""
                onChange={(e) => {
                    setSearchOrderBy(e.target.value);
                    Analytics.Event('Side Drawer', `Select Featured SortBy: ${e.target.value}`);
                }}
            >
                <option value="Default">Default</option>
                <option value="Views">Views</option>
                <option value="Comments">Comments</option>
                <option value="Likes">Likes</option>
                <option value="Created">Created</option>
                <option value="Area">Area</option>
            </Select>
        </SearchFilterContainer>
    );
};

export default FeaturedSearchFilter;

const SearchFilterContainer = styled.div`
    display: flex;
`;

const Select = styled.select`
    border-radius: 6px;
    font-size: 14px;
    color: ${(props) => props.theme.color.lightGray};
    width: 120px;
    height: 40px;
    padding: 5px;
    margin-right: 14px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 6px;
    border: 1px solid rgb(81, 81, 81);
    margin: 10px 15px 10px 3px;

    option {
        color: rgba(255, 255, 255, 0.6);
        background-color: rgba(0, 0, 0, 0.8);
    }

    :hover {
        border: 1px solid rgba(238 227 8 / 0.6) !important;
        border-color: rgba(238 227 8 / 0.6) !important;
        box-shadow: 0 0 0 0.2rem rgba(238 227 8 / 0.1) !important;
    }
`;
