import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedClickToSearch } from '../../../../../../store/Map/MapSelection/selectors';
import { StyledButton } from '../../../../../Shared/styled-button';
import styled from 'styled-components';
import Analytics from '../../../../../../lib/user-analytics';
import { actionSetSelectedClickToSearch } from '../../../../../../store/Map/MapSelection/actions';
import React from 'react';

const MapSearchClickToSearch = () => {
    const clickToSearch = useSelector(selectSelectedClickToSearch);
    const dispatch = useDispatch();

    const clickToSearchButtonText = clickToSearch ? 'Deactivate click to Search' : 'Activate click to Search';
    return (
        <React.Fragment>
            <ClickToSearchText>Interaction Behavior</ClickToSearchText>
            <ClickToSearchInfo>
                Are you tired of the map updating every time you move around? Click-to-search allows you to update the
                map only when you're ready, try it today!
            </ClickToSearchInfo>
            <ClickToSearchButton
                onClick={() => {
                    dispatch(actionSetSelectedClickToSearch(!clickToSearch));
                    Analytics.Event('Side Drawer', `Click to Search ${clickToSearch ? 'Deactivated' : 'Activated'}`);
                }}
            >
                {clickToSearchButtonText}
            </ClickToSearchButton>
        </React.Fragment>
    );
};

export default MapSearchClickToSearch;

const ClickToSearchText = styled.div`
    color: white;
    font-size: 19px;
    padding: 0px 0px;
`;

const ClickToSearchInfo = styled.p`
    color: rgba(255, 255, 255, 0.87);
    font-size: 14px;
    padding: 3px 0px;
`;

const ClickToSearchButton = styled(StyledButton)`
    margin: 0px auto 10px auto;
    border-radius: 6px;
    padding: 8px 15px;
`;
