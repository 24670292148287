import { LatLng, LatLngBounds } from 'leaflet';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../../../api/model';
import ListingHelper from '../../../../../lib/listing-helper';
import UriHelper from '../../../../../lib/uri-helper';
import Analytics from '../../../../../lib/user-analytics';
import { actionFlyToOnMap } from '../../../../../store/App/actions';
import {
    actionSetHighlightedListingId,
    actionSetHighlightedListingGroup,
    actionSetSearchDrawListings,
} from '../../../../../store/Map/MapSelection/actions';
import { selectHighlightedListingGroup } from '../../../../../store/Map/MapSelection/selectors';
import { selectUserListings } from '../../../../../store/Map/UserProfile/selectors';
import { MapImage } from './profile-featured-maps';

const ProfileAllMaps = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredListings, setFilteredListings] = useState<ListingDTO[]>([]);

    const listings = useSelector(selectUserListings);
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const gridRef = useRef<HTMLDivElement | null>(null);

    const dispatch = useDispatch();
    const flyTo = (position: LatLng | LatLngBounds) => dispatch(actionFlyToOnMap(position));

    const searchFilter = (title: string, tags: string[], searchTerm: string): boolean => {
        if (searchTerm?.length > 0) {
            const searchTermLowerCase = searchTerm.toLocaleLowerCase();
            return `${title} ${tags?.join(' ')}`.toLocaleLowerCase().includes(searchTermLowerCase);
        }
        return true;
    };

    useEffect(() => {
        setFilteredListings(listings.filter((tl) => searchFilter(tl.title, tl.tags, searchTerm.trim())));
    }, [listings, searchTerm]);

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));
    const invalidateHighlightedTileLayerGroup = () => dispatch(actionSetHighlightedListingGroup(undefined));

    const onMouseEnter = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
        dispatch(
            actionSetSearchDrawListings([filteredListings?.find((listing) => listing.id === tileLayerId) as ListingDTO])
        );

        invalidateHighlightedTileLayerGroup();
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
        dispatch(actionSetSearchDrawListings([]));
    };

    return (
        <Container>
            <SearchContainer>
                <SearchIcon className="fa fa-search" />
                <SearchInput
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    onChange={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                />
            </SearchContainer>
            <MapsContainer>
                {filteredListings.length > 0 ? (
                    <MapGrid ref={gridRef}>
                        {filteredListings.map((l, key) => {
                            const isHighlighted = highlightedTileLayerGroup?.tileLayers[0].id === l.id;
                            return (
                                <MapsGrid
                                    maxHeight={gridRef.current?.clientWidth && gridRef.current.clientWidth / 4}
                                    key={key}
                                    onMouseEnter={() => onMouseEnter(l.id)}
                                    onMouseLeave={() => onMouseLeave()}
                                    onClick={() => {
                                        UriHelper.navigateToMap(l.id);
                                        flyTo(l.boundingBox);
                                        Analytics.Event('Profile sidedraw', 'Clicked to view', l.id);
                                        ApiAnalytics.postAnalyticsListing(
                                            AnalyticsAction.VIEW,
                                            AnalyticsNote.USER_PROFILE,
                                            l.id
                                        );
                                    }}
                                >
                                    <MapImage
                                        isHighlighted={isHighlighted}
                                        src={ListingHelper.getPreviewUrlForListing(l.id, 'small')}
                                        title={l.title || ''}
                                    />
                                </MapsGrid>
                            );
                        })}
                    </MapGrid>
                ) : (
                    <ErrorLabel>No Maps were found</ErrorLabel>
                )}
            </MapsContainer>
        </Container>
    );
};

export default ProfileAllMaps;

const Container = styled.div`
    color: white;
    padding: 0px 15px;
`;

const MapsContainer = styled.div`
    margin-top: 10px;
`;

const MapGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-auto-rows: 1fr;
    max-height: calc(100vh - 190px); // 190px is the height of the header and the search bar
    overflow-y: auto;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #eed926;
        border-radius: 4px;
    }
`;

const MapsGrid = styled.div<{ maxHeight?: number }>`
    padding: 3px;
    width: 100%;
    height: 100%;
    max-height: ${({ maxHeight }) => `${maxHeight}px` || '50px'};
    aspect-ratio: 1/1;
`;

const SearchContainer = styled.div`
    width: 100%;
`;

const SearchIcon = styled.i`
    size: 20px;
    padding: 10px;
    position: relative;
    color: #eed926;
    top: 35px;
`;

const SearchInput = styled.input`
    background: transparent;
    color: #ffffff;
    padding: 5px;
    width: 100%;
    border: 1px solid rgb(255 255 255 / 0.3);
    border-radius: 6px;
    padding-left: 30px;
    min-height: 35px;

    &:focus {
        background: transparent;
        outline: #eed926;
        border: 1px solid #eed926;
        color: #ffffff;
    }
`;

const ErrorLabel = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    height: 25px;
    color: #ffffff;

    opacity: 0.3;
    margin-left: 5px;
`;
