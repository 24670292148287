import { isMobileVersion } from '../../../../../lib/soar-helper';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ApiAnalytics from '../../../../../api/api-analytics';
import { AnalyticsAction, AnalyticsNote, ListingDTO } from '../../../../../api/model';
import ListingHelper from '../../../../../lib/listing-helper';
import UriHelper from '../../../../../lib/uri-helper';
import Analytics from '../../../../../lib/user-analytics';
import {
    actionSetHighlightedListingId,
    actionSetHighlightedListingGroup,
    actionSetSearchDrawListings,
} from '../../../../../store/Map/MapSelection/actions';
import { selectHighlightedListingGroup } from '../../../../../store/Map/MapSelection/selectors';
import ProfileAllMaps from './profile-all-maps';
import SideDrawerNested from '../../Shared/side-drawer-nested';

interface ProfileFeaturedMapsProps {
    listings: ListingDTO[];
}

const ProfileFeaturedMaps = ({ listings }: ProfileFeaturedMapsProps) => {
    const highlightedTileLayerGroup = useSelector(selectHighlightedListingGroup);

    const dispatch = useDispatch();

    const setTileLayerHighlighted = (tileLayerId: number | undefined) =>
        dispatch(actionSetHighlightedListingId(tileLayerId));
    const invalidateHighlightedTileLayerGroup = () => dispatch(actionSetHighlightedListingGroup(undefined));

    const onMouseEnter = (tileLayerId: number) => {
        setTileLayerHighlighted(tileLayerId);
        dispatch(actionSetSearchDrawListings([listings?.find((listing) => listing.id === tileLayerId) as ListingDTO]));

        invalidateHighlightedTileLayerGroup();
    };

    const onMouseLeave = () => {
        setTileLayerHighlighted(undefined);
        dispatch(actionSetSearchDrawListings([]));
    };

    return (
        <Container>
            {listings?.length > 0 ? (
                <ProfileHeader>
                    <Title>Recent Maps</Title>
                    <SideDrawerNested
                        controlText="Share"
                        controlComponent={
                            <AllMapsButton>
                                <AllMapsLabel>All Maps</AllMapsLabel>
                                <AllMapsArrow src="/assets/user-profile-logos/arrow.png" />
                            </AllMapsButton>
                        }
                    >
                        <ProfileAllMaps />
                    </SideDrawerNested>
                </ProfileHeader>
            ) : null}

            {listings?.length ? (
                <FeaturedMaps>
                    {listings.slice(0, isMobileVersion ? 8 : 4).map((l, key) => {
                        const isHighlighted = highlightedTileLayerGroup?.tileLayers[0].id === l.id;
                        return (
                            <MapsGrid
                                key={key}
                                onMouseEnter={() => onMouseEnter(l.id)}
                                onMouseLeave={() => onMouseLeave()}
                                onClick={() => {
                                    UriHelper.navigateToPath(`/maps/${l.id}`);
                                    Analytics.Event('Profile sidedraw', 'Clicked to view', l.id);
                                    ApiAnalytics.postAnalyticsListing(
                                        AnalyticsAction.VIEW,
                                        AnalyticsNote.USER_PROFILE,
                                        l.id
                                    );
                                }}
                            >
                                <MapImage
                                    isHighlighted={isHighlighted}
                                    src={ListingHelper.getPreviewUrlForListing(l.id, 'small')}
                                    title={l.title || ''}
                                />
                            </MapsGrid>
                        );
                    })}
                </FeaturedMaps>
            ) : null}
        </Container>
    );
};

export default ProfileFeaturedMaps;

const Container = styled.div`
    color: white;
    padding: 15px 15px 10px 15px;
`;

const ProfileHeader = styled.div`
    width: 100%;
`;

const Title = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;

    color: #ffffff;
    margin-left: 5px;
    margin-top: 5px;
`;

const AllMapsButton = styled.div`
    cursor: pointer;
    float: right;
    margin-top: -18px;
`;

const AllMapsLabel = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 100%;
    color: #ffffff;
    opacity: 0.3;
    margin-right: 5px;
`;

const AllMapsArrow = styled.img`
    margin-bottom: 4px;
    margin-left: 5px;
`;

const FeaturedMaps = styled.div`
    margin-top: 10px;
    margin-bottom: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    grid-auto-rows: 1fr;
`;

const MapsGrid = styled.div`
    padding: 3px;
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
`;

export const MapImage = styled.img<{ isHighlighted: boolean }>`
    width: inherit;
    height: inherit;
    aspect-ratio: 1;

    border-radius: 6px;
    cursor: pointer;
    border: ${(props) => (props.isHighlighted ? '2px solid #eed926' : '')};
    -webkit-box-shadow: ${(props) => (props.isHighlighted ? '0px 0px 26px 10px rgba(238, 217, 38, 0.49)' : '')};
    -moz-box-shadow: ${(props) => (props.isHighlighted ? '0px 0px 26px 10px rgba(238, 217, 38, 0.49)' : '')};
    box-shadow: ${(props) => (props.isHighlighted ? '0px 0px 26px 10px rgba(238, 217, 38, 0.49)' : '')};

    &:hover {
        border: 2px solid #eed926;
        -webkit-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        -moz-box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
        box-shadow: 0px 0px 26px 10px rgba(238, 217, 38, 0.49);
    }

    @media only screen and (max-width: 600px) {
        width: calc((100vw - 60px) / 4);
        height: calc((100vw - 60px) / 4);
    }
`;
