import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Constants from '../../../../constants';
import { addSentinelParamsToUrl, getDateFromShareLink, getLayerFromShareLink } from '../../../../lib/sentinel-service';
import UriHelper, { useSentinelQuery } from '../../../../lib/uri-helper';
import Analytics from '../../../../lib/user-analytics';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { actionFlyTo } from '../../../../store/App/actions';
import {
    actionSentinelBeginBoxSelect,
    actionSentinelChangeOpacity,
    actionSentinelClearFeatures,
    actionSentinelEndBoxSelect,
    actionSentinelFetchFeatures,
    actionSentinelResetSelectedAOI,
    actionSentinelResetSelectedFeature,
    actionSentinelSelectAOI,
    actionSentinelSelectFeature,
} from '../../../../store/Map/Sentinel/actions';
import { SentinelMetadata } from '../../../../store/Map/Sentinel/model';
import {
    selectSentinelError,
    selectSentinelFeatures,
    selectSentinelFeaturesLoading,
    selectSentinelIsBoxSelectActive,
    selectSentinelSelectedAOI,
    selectSentinelSelectedFeature,
} from '../../../../store/Map/Sentinel/selectors';
import { SideDrawerMode } from '../../../../store/SideDrawer/model';
import { LoginModalMode } from '../../../Registration/login-enum';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';
import { PulseLoader } from '../../../Shared/pulse-loader';
import DrawerHint from '../../drawer-hint';
import {
    SatelliteSubmitButton,
    Content,
    EmptyTeaserTitle,
    Logo,
    LogoContainer,
    ShowMoreButton,
    ShowMoreContainer,
    TeaserIcon,
    TeaserText,
} from '../satellite-drawer-styles';
import SatelliteLoading from '../satellite-loading';
import SentinelFilter, { DateRange, last30Days } from './sentinel-filter';
import SentinelItem from './sentinel-item';
import SentinelLowResolutionNotice from './sentinel-low-resolution-notice';

import { SideDrawerBackButton } from '../../SideDrawer/Shared/side-drawer-buttons';

const moment = require('moment');

const SentinelDrawer = () => {
    const bbox = useSelector(selectSentinelSelectedAOI);
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const isBoxSelectActive = useSelector(selectSentinelIsBoxSelectActive);
    const sentinelFeatures = useSelector(selectSentinelFeatures);
    const sentinelFeaturesLoading = useSelector(selectSentinelFeaturesLoading);
    const sentinelError = useSelector(selectSentinelError);

    const dispatch = useDispatch();
    const beginBoxSelect = () => dispatch(actionSentinelBeginBoxSelect());
    const endBoxSelect = () => dispatch(actionSentinelEndBoxSelect());
    const resetSelectedAOI = () => dispatch(actionSentinelResetSelectedAOI());
    const resetSelectedSentinelFeature = () => dispatch(actionSentinelResetSelectedFeature());
    const clearSentinelFeatures = () => dispatch(actionSentinelClearFeatures());

    const [layer, setLayer] = useState<SentinelMetadata>(Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR);
    const [dateRange, setDateRange] = useState<DateRange>(last30Days);
    const [showMoreLoading, setShowMoreLoading] = useState(false);

    const isLoggedIn = useSelector(selectLoggedIn);

    const sentinelShareFeature = useSentinelQuery();

    const invalidateSentinelResults = () => {
        clearSentinelFeatures();
        resetSelectedAOI();
        resetSelectedSentinelFeature();
        endBoxSelect();
        setLayer(Constants.OVERLAY_DATA.SENTINEL.TRUE_COLOR);
    };

    useEffect(() => {
        if (sentinelShareFeature) {
            dispatch(actionFlyTo(sentinelShareFeature.bbox, undefined, true));
            dispatch(actionSentinelSelectAOI(sentinelShareFeature.bbox));
            if (UriHelper.tryGetParam('time')) {
                setDateRange(getDateFromShareLink(sentinelShareFeature.date));
            }
            if (UriHelper.tryGetParam('layerKey')) {
                setLayer(getLayerFromShareLink(sentinelShareFeature.layer));
            }
            dispatch(actionSentinelSelectFeature(sentinelShareFeature));
        }

        return () => {
            invalidateSentinelResults();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedSentinelFeature) {
            addSentinelParamsToUrl(selectedSentinelFeature);
        }
    }, [selectedSentinelFeature]);

    useEffect(() => {
        //reset opacity
        dispatch(actionSentinelChangeOpacity(undefined));
        if (bbox && layer) {
            dispatch(actionSentinelFetchFeatures(bbox, dateRange.startDate, dateRange.endDate, layer));
        }
    }, [bbox, layer, dateRange, dispatch]);

    const handleShowMore = () => {
        Analytics.Event('Satellite - Sentinel', 'Clicked show more');
        setShowMoreLoading(true);
        const newStartDate = moment(dateRange.startDate).subtract(1, 'months').toDate();
        setDateRange({ ...dateRange, startDate: newStartDate });
    };

    useEffect(() => {
        if (sentinelFeatures) {
            setShowMoreLoading(false);
        }
    }, [sentinelFeatures]);

    const isLowResolution = sentinelFeatures && sentinelFeatures[0].resolution !== '10m';

    const reset = () => {
        UriHelper.removeAllSentinelParametersFromURI();
        if (sentinelFeatures) {
            invalidateSentinelResults();
        } else {
            clearSentinelFeatures();
            resetSelectedAOI();
            UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
            endBoxSelect();
            Analytics.Event('Satellite - Sentinel', 'Clicked back arrow');
        }
    };

    if (!isLoggedIn) {
        return (
            <LoginRegisterDialog
                isOpen={true}
                initialMode={LoginModalMode.LOGIN}
                onClose={(isUserLoggedIn) => {
                    if (!isUserLoggedIn) {
                        UriHelper.navigateToDrawer(SideDrawerMode.SATELLITE);
                    }
                }}
            />
        );
    }

    return (
        <React.Fragment>
            <SideDrawerBackButton onClick={reset} />

            <LogoContainer>
                <Logo src="/assets/floating-drawer-satellite-icons/satellite-sentinel-logo.png" />
            </LogoContainer>
            {!sentinelFeatures && !sentinelFeaturesLoading && sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <SatelliteSubmitButton
                        id="draw-aoi-button"
                        onClick={() => {
                            invalidateSentinelResults();
                            Analytics.Event('Satellite - Sentinel', 'Cleared Sentinel Feature');
                        }}
                    >
                        RESET AREA OF INTEREST
                    </SatelliteSubmitButton>
                </Content>
            ) : null}

            {!sentinelFeaturesLoading && !sentinelFeatures && !sentinelError ? (
                <Content>
                    <EmptyTeaserTitle />
                    <TeaserIcon src="/assets/floating-drawer-icons/map-teaser-icon.svg" />
                    <TeaserText>Click the button and drag an Area of Interest.</TeaserText>
                    <TeaserText>Let's see what we can find!</TeaserText>
                    <SatelliteSubmitButton
                        id="draw-aoi-button"
                        disabled={isBoxSelectActive}
                        onClick={() => {
                            beginBoxSelect();
                            Analytics.Event('Satellite - Sentinel', 'Clicked draw area of interest');
                        }}
                    >
                        DRAW AOI
                    </SatelliteSubmitButton>
                </Content>
            ) : null}

            {sentinelFeaturesLoading && !showMoreLoading && !sentinelError ? (
                <Content>
                    <SatelliteLoading />
                </Content>
            ) : null}

            {sentinelFeatures && !sentinelError && (!sentinelFeaturesLoading || showMoreLoading) ? (
                <React.Fragment>
                    <SentinelFilter
                        onSelectLayer={(layer) => setLayer(layer)}
                        onSelectDateRange={(dateRange) => setDateRange(dateRange)}
                        layer={layer}
                        dateRange={dateRange}
                    />
                    <SentinelContent>
                        {isLowResolution ? <SentinelLowResolutionNotice /> : null}

                        <SentinelItems>
                            {sentinelFeatures.map((t, index) => (
                                <SentinelItem key={index} feature={t} selectedFeature={selectedSentinelFeature} />
                            ))}
                        </SentinelItems>

                        <ShowMoreContainer>
                            {showMoreLoading ? (
                                <ShowMoreButton>
                                    <PulseLoader />
                                </ShowMoreButton>
                            ) : (
                                <ShowMoreButton
                                    onClick={() => {
                                        handleShowMore();
                                        Analytics.Event('Satellite - Sentinel', 'Clicked show more');
                                    }}
                                >
                                    SHOW MORE
                                </ShowMoreButton>
                            )}
                        </ShowMoreContainer>
                    </SentinelContent>
                </React.Fragment>
            ) : null}
            {sentinelError ? <DrawerHint error>{sentinelError.message || `${sentinelError}`}</DrawerHint> : null}
        </React.Fragment>
    );
};

export default SentinelDrawer;

const SentinelContent = styled(Content)`
    display: flex;
    flex-direction: column;
`;

const SentinelItems = styled.div`
    overflow-y: auto;
    flex-basis: calc(100vh - 250px);

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #343a40;
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: #343a40;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eed926;
        border-radius: 4px;
    }
`;
