import { useSelector } from 'react-redux';
import { selectSearchDrawListings } from '../../../../store/Map/MapSelection/selectors';
import MapSearchCluster from '../PolygonCluster/map-search-cluster';

const MapSearchDrawProvider = () => {
    const listings = useSelector(selectSearchDrawListings);
    return <MapSearchCluster listings={listings || []} />;
};

export default MapSearchDrawProvider;
