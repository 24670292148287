import React from 'react';
import { useSelector } from 'react-redux';
import UploadMapWorkflow from './UploadWorkFlow/upload-map-workflow';
import MapUploadStatus from './UploadWorkFlow/map-upload-status';
import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';
import { selectLoggedIn } from '../../../../store/Account/selectors';
import { LoginModalMode } from '../../../Registration/login-enum';
import LoginRegisterDialog from '../../../Registration/login-register-dialog';

const SideDrawerUpload = () => {
    const isLoggedIn = useSelector(selectLoggedIn);

    if (!isLoggedIn)
        return (
            <LoginRegisterDialog
                isOpen={true}
                initialMode={LoginModalMode.LOGIN}
                onClose={(isLoggedIn) => {
                    Analytics.Event('Upload', 'Closed Login');
                    if (!isLoggedIn) {
                        UriHelper.navigateToPath('/maps');
                    }
                }}
            />
        );

    return (
        <React.Fragment>
            <MapUploadStatus />
            <UploadMapWorkflow />
        </React.Fragment>
    );
};

export default SideDrawerUpload;
