import React, { useEffect } from 'react';
import styled from 'styled-components';
import MapSearchDrawLabel from './ListingsDrawer/SearchListings/map-search-draw-label';
import UriHelper from '../../../lib/uri-helper';
import MapSearchDrawInput from './ListingsDrawer/SearchListings/map-search-draw-input';
import { useSelector } from 'react-redux';
import { selectActiveSubdomainTileLayer } from '../../../store/Map/Subdomain/selectors';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import { SideDrawerControlProps } from './side-drawer-util';
import Analytics from '../../../lib/user-analytics';

interface SideDrawerSearchCloseLabelProps {
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
    showInput: boolean;
    setShowInput: (showInput: boolean) => void;
    drawerControl: SideDrawerControlProps;
}

const SideDrawerSearchCloseLabel = ({
    expanded,
    setExpanded,
    showInput,
    setShowInput,
    drawerControl,
}: SideDrawerSearchCloseLabelProps) => {
    const { hasCloseIcon, closeIconPosition, closeToPath } = drawerControl;
    const activeMap = useSelector(selectActiveAllMap);
    const activeSubdomainMap = useSelector(selectActiveSubdomainTileLayer);
    const activeListing = activeSubdomainMap || activeMap;

    useEffect(() => {
        if (!activeListing) {
            setShowInput(true);
        }
    }, [activeListing, setShowInput]);

    if (activeListing && showInput) {
        return (
            <MapSearchDrawLabel
                expanded={expanded}
                toggleDrawer={() => setExpanded(true)}
                mapTitle={activeListing?.title || 'Awesome Map'}
            />
        );
    }

    if (showInput) {
        return (
            <MapSearchDrawInput
                expanded={expanded}
                toggleDrawer={() => {
                    UriHelper.navigateToPath('/maps');
                    setExpanded(true);
                }}
            />
        );
    }

    return (
        <React.Fragment>
            {!hasCloseIcon ? (
                <CloseIcon
                    id="side-drawer-close-icon"
                    onClick={() => {
                        Analytics.Event('Side Drawer', `Clicked close to  ${closeToPath}`);
                        UriHelper.navigateToPath(closeToPath);
                    }}
                    className="fa fa-close"
                    positionLeft={closeIconPosition}
                />
            ) : null}
        </React.Fragment>
    );
};

export default SideDrawerSearchCloseLabel;

const CloseIcon = styled.i<{ positionLeft: string }>`
    float: right;
    padding: 10px;
    font-size: 18px;
    color: white;
    cursor: pointer;
    position: absolute;
    z-index: 99999;
    height: 40px;
    left: ${(props) => props.positionLeft};
    top: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;
