import { useState } from 'react';
import styled from 'styled-components';
import { ListingDTO } from '../../../../api/model';
import ListingHelper from '../../../../lib/listing-helper';
import Analytics from '../../../../lib/user-analytics';
import UriHelper from '../../../../lib/uri-helper';
import TemporalCategoryControl from '../../Shared/TemporalCategory/temporal-category-control';

interface MobileSearchCardProps {
    listing: ListingDTO;
}

const FeaturedCardGridView = ({ listing }: MobileSearchCardProps) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleSelectedListing = (listingId: number) => {
        Analytics.Event('Featured', 'Selected Listing', listingId);
        UriHelper.navigateToPath(`/maps/${listingId}`);
    };

    return (
        <SearchCardContainer className={imageLoaded ? 'fade-in' : ''}>
            <ImageContainer onClick={(e) => e.preventDefault()}>
                <ImgContainer onClick={() => handleSelectedListing(listing.id)}>
                    <SearchCardImage
                        src={ListingHelper.getPreviewUrlForListing(listing.id, 'small')}
                        onClick={() => handleSelectedListing(listing.id)}
                        onLoad={() => setImageLoaded(true)}
                        loading="lazy"
                    />
                </ImgContainer>
                <OverlaidDiv onClick={() => handleSelectedListing(listing.id)} data-testid="handle-image-select">
                    <OverlayDetails>
                        <OverlayTitle>{listing.title}</OverlayTitle>
                        <TemporalCategoryControl listing={listing} margin="5px 0px 0px 0px" showTemporalCategories />
                        {listing.avatarUrl ? (
                            <OverlayBottomDetails>
                                <UserInfo>
                                    <Avatar src={listing.avatarUrl} alt={listing.userName} />
                                    <UserName>{listing.userName}</UserName>
                                </UserInfo>
                            </OverlayBottomDetails>
                        ) : null}
                    </OverlayDetails>
                </OverlaidDiv>
            </ImageContainer>
        </SearchCardContainer>
    );
};

export default FeaturedCardGridView;

const ImageContainer = styled.a`
    display: block;
    height: 100%;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    overflow: hidden;
`;

const ImgContainer = styled.div`
    margin: 0;
    position: relative;
    vertical-align: middle;
    overflow: hidden;
    height: 100%;
    width: 100%;

    img {
        cursor: pointer;
        border: 1px solid transparent;
        width: 100%;
        display: flex;
        overflow: hidden;
        height: 100%;
        object-fit: cover;
    }
`;

const OverlaidDiv = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    border-radius: 6px;
    background: transparent;
    color: #ffffff;

    ${ImageContainer}:hover & {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: rgba(0, 0, 0, 0.7);
        transition: all 0.2s 0s ease-in-out;
        border: 1px solid #eed923;
    }
`;

const OverlayDetails = styled.div`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
`;

const OverlayTitle = styled.p`
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: clamp(1rem, 1.2rem, 1.2rem);
    color: #eed926;
    text-align: center;
    word-break: break-word;
    max-width: 100%;
    margin: 0;

    ${ImageContainer}:hover & {
        transition: all 0.3s ease-in-out;
        opacity: 1;
    }
`;

const SearchCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background-color: hsla(0, 100%, 100%, 0.01);
    opacity: 0;
    height: 120px;
    overflow: hidden;
    transition: opacity 0.5s, height 0.5s;
    cursor: pointer;

    &.fade-in {
        box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
            0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
        margin: 6px 3px;
        border: 1px solid rgb(255 255 255 / 10%);
        opacity: 1;
        height: auto;
    }

    &:hover {
        img {
            opacity: 1;
            transform: scale(1.03);
            transition: 0.5s ease;
        }
    }
`;

const SearchCardImage = styled.img`
    height: auto;
    max-width: 100%;
    object-fit: contain;
    border-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: hsla(0, 100%, 100%, 0.02);
    box-shadow: 0px 6px 10px 0px hsla(0, 0%, 0%, 0.14), 0px 1px 18px 0px hsla(0, 0%, 0%, 0.12),
        0px 3px 5px -1px hsla(0, 0%, 0%, 0.2);
`;

const OverlayBottomDetails = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0px;
    z-index: 1;

    ${ImageContainer}:hover & {
        transition: all 0.3s 0s ease-in-out;
        opacity: 1;
    }
`;

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    margin: 0 12px 12px;
`;

const Avatar = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
`;

const UserName = styled.div`
    color: rgb(221, 221, 221);
    font-size: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
`;
