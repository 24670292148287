import React from 'react';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { selectMyProfile } from '../../../store/Account/selectors';
import { LoginModalMode } from '../../Registration/login-enum';
import LoginRegisterDialog from '../../Registration/login-register-dialog';
import ReportMapContent from './report-map-content';
import ReportMapMenu from './report-map-menu';

const ReportMap = () => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const toggleRef = useRef<HTMLInputElement>(null);

    const myProfile = useSelector(selectMyProfile);

    const handleReportImage = () => {
        if (!myProfile) {
            setShowLogin(true);
            return;
        }
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <React.Fragment>
            <ReportMapDropdown
                direction="right"
                onClick={(e) => e.stopPropagation()}
                isOpen={dropdownOpen}
                toggle={handleReportImage}
            >
                <ReportToggle
                    tag="span"
                    className="report-map-toggle"
                    id="report-map-toggle"
                    data-tooltip-id={`report-map`}
                    data-tooltip-content={`Report Map`}
                >
                    <ReportEllipsisIcon title={`Report Map`} className={'fa fa-flag report-map-icon'} ref={toggleRef} />
                </ReportToggle>

                {dropdownOpen && toggleRef?.current ? (
                    <React.Fragment>
                        <ReportMapMenu toggleRef={toggleRef.current}>
                            <ReportMapContent handleReportIsOpen={() => setDropdownOpen(!dropdownOpen)} />
                        </ReportMapMenu>
                    </React.Fragment>
                ) : null}
            </ReportMapDropdown>
            {showLogin && (
                <LoginRegisterDialog
                    isOpen={true}
                    initialMode={LoginModalMode.LOGIN}
                    onClose={() => setShowLogin(false)}
                />
            )}
        </React.Fragment>
    );
};

export default ReportMap;

const ReportMapDropdown = styled(Dropdown)``;

const ReportToggle = styled(DropdownToggle)`
    color: white;
    width: 50px;

    &:hover {
        color: #eed926;
    }

    &:after {
        display: block;
    }
`;

const ReportEllipsisIcon = styled.i`
    font-size: 16px;
    color: white;
    padding: 8px 2px;
    cursor: pointer;
    margin-right: 5px;
`;
