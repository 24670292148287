import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Collapse } from 'reactstrap';
import Analytics from '../../../../../../lib/user-analytics';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedSearchCategory } from '../../../../../../store/Map/MapSelection/selectors';
import { actionSetSelectedSearchCategory } from '../../../../../../store/Map/MapSelection/actions';

export const categoryOptions = [
    { value: 'AGRICULTURE', label: 'Agriculture' },
    { value: 'CLIMATE', label: 'Climate' },
    { value: 'EARTH-ART', label: 'Earth Art' },
    { value: 'ECONOMIC', label: 'Economic' },
    { value: 'ENVIRONMENT', label: 'Environment' },
    { value: 'GEOLOGY', label: 'Geology' },
    { value: 'HISTORY', label: 'History' },
    { value: 'MARINE', label: 'Marine' },
    { value: 'POLITICAL', label: 'Political' },
    { value: 'TERRAIN', label: 'Terrain' },
    { value: 'TRANSPORT', label: 'Transport' },
    { value: 'URBAN', label: 'Urban' },
];

export const CATEGORY_DEFAULT = undefined;

const MapSearchCategoryFilter = () => {
    const [showFilters, setShowFilters] = useState(false);

    const searchCategory = useSelector(selectSelectedSearchCategory);

    const dispatch = useDispatch();

    const handleCategorySelect = (category: string) => {
        if (searchCategory === category) {
            dispatch(actionSetSelectedSearchCategory(undefined));
        } else {
            dispatch(actionSetSelectedSearchCategory(category));
        }
    };

    useEffect(() => {
        if (searchCategory) {
            Analytics.Event('Side Drawer', `Clicked Category ${searchCategory}`);
            dispatch(actionSetSelectedSearchCategory(searchCategory));
            setShowFilters(true);
        } else {
            dispatch(actionSetSelectedSearchCategory(undefined));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchCategory]);

    return (
        <React.Fragment>
            <DrawFilter onClick={() => setShowFilters(!showFilters)}>
                Categories
                <DrawFilterArrow open={showFilters} className="fa fa-chevron-up" />
            </DrawFilter>
            <DrawFilterCollapseContainer isOpen={showFilters}>
                {categoryOptions.map((category) => (
                    <CategoryFilterItem key={category.value} onClick={() => handleCategorySelect(category.value)}>
                        <Checkbox
                            type="checkbox"
                            checked={searchCategory === category.value}
                            onChange={() => handleCategorySelect(category.value)}
                        />
                        {category.label}
                    </CategoryFilterItem>
                ))}
            </DrawFilterCollapseContainer>
        </React.Fragment>
    );
};

export default MapSearchCategoryFilter;

const DrawFilter = styled.div`
    color: white;
    font-size: 16px;
    padding: 5px 0px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const DrawFilterArrow = styled.i<{ open: boolean }>`
    display: inline-block;
    margin-right: 13px;
    transition: transform 0.3s ease;
    transform: rotate(${(props) => (props.open ? '180deg' : '0deg')});
`;

const CategoryFilterItem = styled.div`
    color: #eed926;
    font-size: 16px;
    padding: 5px 0px;
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: left;

    &:hover {
        background: rgba(255, 255, 255, 0.1);
    }
`;

const Checkbox = styled.input`
    margin: 0px 10px;
`;

const DrawFilterCollapseContainer = styled(Collapse)`
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
`;
