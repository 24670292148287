import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ApiFeaturedContent from '../../api/api-featured-content';
import { ListingDTO } from '../../api/model';
import { SegmentContainer, SegmentTitle, SegmentTitleIcon } from './search-container';
import moment from 'moment';
import SearchContentCard from './search-content-card';
import Analytics from '../../lib/user-analytics';
import { MarkdownViewer } from '../Shared/markdown-viewer';

interface SearchContentCurrentEventsProps {
    handleSelectListing: (id: number) => void;
    handleSelectProfile: (userId: string) => void;
}

const SearchContentCurrentEvents = (props: SearchContentCurrentEventsProps) => {
    const [currentEventsListing, setCurrentEventsListing] = useState<ListingDTO | undefined>(undefined);

    useEffect(() => {
        ApiFeaturedContent.getCurrentEventsListing().then((res) => setCurrentEventsListing(res));
    }, []);

    if (!currentEventsListing) {
        return <React.Fragment />;
    }

    return (
        <SegmentContainer>
            <SegmentTitle>
                <SegmentTitleIcon src="/assets/search-content/emoji-current-events.svg" />
                Keep a bird's eye on <span>current events</span>
            </SegmentTitle>
            <ContainerContent>
                <ImageContainer>
                    <SearchContentCard
                        id={currentEventsListing.id}
                        onClick={(id) => {
                            props.handleSelectListing(id);
                            Analytics.Event('Search Bar', 'Clicked Map From Current Event', id);
                        }}
                        height="250px"
                        title={currentEventsListing.title}
                        key={currentEventsListing.id}
                    />
                </ImageContainer>
                <ContentContainer>
                    <Title>{currentEventsListing.title}</Title>
                    <Subtitle>
                        by&nbsp;
                        <UserProfileLink
                            onClick={() => props.handleSelectProfile(currentEventsListing.userId)}
                            title="Open Profile"
                        >
                            {currentEventsListing.userName ? currentEventsListing.userName : 'Soar User'}
                        </UserProfileLink>
                        {currentEventsListing.communityModerator && currentEventsListing.reputable ? (
                            <span title="Reputable Community Moderator">🏆😎</span>
                        ) : currentEventsListing.communityModerator ? (
                            <span title="Community Moderator">😎</span>
                        ) : currentEventsListing.reputable ? (
                            <span title="Hero User">🏆</span>
                        ) : null}
                    </Subtitle>
                    <UploadDate>{moment(currentEventsListing.dateUploaded).fromNow()}</UploadDate>
                    <Description>
                        <MarkdownViewer text={currentEventsListing.description} />
                    </Description>
                    <ShowMoreLink onClick={() => props.handleSelectListing(currentEventsListing.id)} title="Show More">
                        View the Map
                    </ShowMoreLink>
                    <TagContainer>
                        {currentEventsListing.tags &&
                            currentEventsListing.tags.map((tag, index) => {
                                return <Tag key={`${tag}-${index}`}>{tag}</Tag>;
                            })}
                    </TagContainer>
                </ContentContainer>
            </ContainerContent>
        </SegmentContainer>
    );
};

export default SearchContentCurrentEvents;

const ContainerContent = styled.div`
    display: flex;
    width: 100%;
    min-height: 256px;
`;

const ImageContainer = styled.div`
    flex: 1;
    cursor: pointer;
`;

const ContentContainer = styled.div`
    flex: 2;
    margin: 0 16px;
`;

const Title = styled.h2`
    flex: 0 1 auto;
    margin: 0 0 4px 0;
    display: inline-block;
    color: white;
    font-weight: 700;
    text-align: left;
    line-height: 1;
    font-size: 30px;
    word-break: break-word;
    text-decoration: none !important;
`;

const Subtitle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -3px;
    color: white;
    font-weight: 400;
    font-size: 16px;
    text-align: left;

    span {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        padding: 0 2px 0 3px;
        align-self: center;
        cursor: help;
    }
`;

const UserProfileLink = styled.div`
    color: #eed926;
    font-weight: 400;
    background: none !important;
    border: none;
    padding: 0 !important;
    text-decoration: underline;
    cursor: pointer;
`;

const UploadDate = styled.span`
    color: rgba(255, 255, 255, 0.6);
    font-size: 1rem;
    line-height: 22px;
`;

const Description = styled.div`
    margin: 8px 0 0 0;
    max-width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    font-weight: 400;
    line-height: 20px;
    color: white;
    word-break: break-word;
`;

const ShowMoreLink = styled.div`
    max-width: 100%;
    display: block;
    color: #eed926;
    font-size: 14px;
    cursor: pointer;
`;
const TagContainer = styled.div`
    max-width: 100%;
    margin-top: 12px;
    flex: 0 1 auto;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    max-height: auto;
    overflow: visible;
`;

const Tag = styled.div`
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding: 1px 3px 3px 3px;
    line-height: 14px;
    opacity: 0.5;
    margin: 0 2px 2px 0;
    color: white;
    width: auto;
    word-break: break-all;
    font-size: 12px;
`;
